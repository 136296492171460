import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Icon } from "../Icon";
import { Tooltip } from "../Tooltip";

type Props = {
  hasConcerns: boolean;
  showViewText?: boolean;
  canCreateReport: boolean;
  sessionEndDateTime: number;
  hasSessionReport: boolean;
  onViewSessionReport: () => void;
};

export const SessionReportDetails = ({
  hasConcerns,
  canCreateReport,
  hasSessionReport,
  sessionEndDateTime,
  showViewText = false,
  onViewSessionReport,
}: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSessionOver, setIsSessionOver] = useState(false);

  useEffect(() => {
    const checkIfSessionOver = () => {
      const now = Date.now();
      if (sessionEndDateTime <= now) return setIsSessionOver(true);
      const timeUntilEnd = sessionEndDateTime - now;
      timeoutRef.current = setTimeout(() => {
        setIsSessionOver(true);
      }, timeUntilEnd + 1);
    };

    checkIfSessionOver();

    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, [sessionEndDateTime]);

  return (
    <div
      className="flex justify-center items-center w-full h-full"
      onClick={() =>
        (canCreateReport || hasSessionReport) &&
        isSessionOver &&
        onViewSessionReport()
      }
    >
      <Tooltip
        tooltipProps={{ place: "left" }}
        className="relative flex gap-x-1.5 cursor-pointer"
        content={
          <div className="max-w-[420px] text-center leading-none">
            {hasSessionReport ? (
              <div className="text-base">View Session Report</div>
            ) : (
              <div className="text-sm">
                {canCreateReport && isSessionOver ? (
                  "Submit Session Report"
                ) : (
                  <>
                    <div className="text-base">No Session Report Submitted</div>
                    <div className="text-sm">
                      Session reports cannot be submitted until the session is
                      over.
                    </div>
                  </>
                )}
              </div>
            )}
            {hasConcerns && (
              <div className="font-sm">This report has session concerns.</div>
            )}
          </div>
        }
      >
        <Icon
          icon="document"
          size={6}
          color={clsx(
            hasSessionReport
              ? "text-emerald-500"
              : canCreateReport && isSessionOver
              ? "text-rose-500"
              : "text-gray-400"
          )}
        />
        {hasConcerns && (
          <div className="absolute top-[1px] left-[13px] h-2 w-2 rounded-full bg-rose-700" />
        )}
        {showViewText && hasSessionReport && isSessionOver && (
          <div className="text-blue-500 font-semibold hover:underline hover:underline-offset-2 mt-[2px]">
            View
          </div>
        )}
      </Tooltip>
    </div>
  );
};
