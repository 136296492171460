import { EventScheduleStatus } from "types/events";

export function getEventScheduleStatus(
  startDateTime: Date,
  endDateTime: Date,
  now: Date
): EventScheduleStatus {
  return now > endDateTime
    ? EventScheduleStatus.CONCLUDED
    : startDateTime <= now && endDateTime >= now
    ? EventScheduleStatus.ONGOING
    : EventScheduleStatus.UPCOMING;
}
