import {
  CohortSessionStudentAttendanceStatus,
  SessionConcernType,
  YesNoSomewhat,
} from "@generated/graphql";
import { YesNoNull } from "components/shared/Inputs/YesOrNoInput";

export type SessionConcern = {
  studentId: string | null;
  concernNotes: string | null;
  concernType: SessionConcernType | null;
};

export type SessionConcernData = {
  hasConcerns: YesNoNull;
  concerns: SessionConcern[];
};

export type TutorCohortStudent = {
  studentId: string;
  studentName: string;
  studentPerformance?: StudentPerformance;
  attendanceStatus?: CohortSessionStudentAttendanceStatus;
};

export type InstructionData = {
  classCode: string | null;
  strand: VIMMathStrands | null;
  instructionNotes: string | null;
  learningTarget: WIMMathLearningTargets | null;
  priorityStandard: VIMElaPriorityStandards | null;
};

export type StudentPerformance = {
  performanceNotes: string | null;
  mastery: YesNoSomewhat | null;
  engagement: YesNoSomewhat | null;
};

export type StudentPerformanceMap = {
  [studentId: string]: StudentPerformance;
};

export enum VIMMathStrands {
  Other = "Other",
  KA = "KA",
  KB = "KB",
  KC = "KC",
  KD = "KD",
  OneA = "1A",
  OneB = "1B",
  OneC = "1C",
  OneD = "1D",
  TwoA = "2A",
  TwoB = "2B",
  TwoC = "2C",
  TwoD = "2D",
  ThreeA = "3A",
  ThreeB = "3B",
  ThreeC = "3C",
  ThreeD = "3D",
  FourA = "4A",
  FourB = "4B",
  FourC = "4C",
  FourD = "4D",
  FiveA = "5A",
  FiveB = "5B",
  FiveC = "5C",
  FiveD = "5D",
  SixA = "6A",
  SixB = "6B",
  SixC = "6C",
  SixD = "6D",
  SevenA = "7A",
  SevenB = "7B",
  SevenC = "7C",
  SevenD = "7D",
  EightA = "8A",
  EightB = "8B",
  EightC = "8C",
  EightD = "8D",
  AlgOneA = "Alg1A",
  AlgOneB = "Alg1B",
  AlgOneC = "Alg1C",
  AlgOneD = "Alg1D",
  GeoA = "GeoA",
  GeoB = "GeoB",
  GeoC = "GeoC",
  GeoD = "GeoD",
  AlgTwoA = "Alg2A",
  AlgTwoB = "Alg2B",
  AlgTwoC = "Alg2C",
  AlgTwoD = "Alg2D",
}

export enum WIMMathLearningTargets {
  Other = "Other",
  AssessedLtOne = "Assessed LT-1",
  AssessedLtTwo = "Assessed LT-2",
  AssessedLtThree = "Assessed LT-3",
  AssessedLtFour = "Assessed LT-4",
  AssessedLtFive = "Assessed LT-5",
  AssessedLtSix = "Assessed LT-6",
  AssessedLtSeven = "Assessed LT-7",
  AssessedLtEight = "Assessed LT-8",
  SupportingLtOne = "Supporting LT-1",
  SupportingLtTwo = "Supporting LT-2",
  SupportingLtThree = "Supporting LT-3",
  SupportingLtFour = "Supporting LT-4",
  SupportingLtFive = "Supporting LT-5",
  SupportingLtSix = "Supporting LT-6",
  SupportingLtSeven = "Supporting LT-7",
  SupportingLtEight = "Supporting LT-8",
  PrereqLtA = "Prereq LT-A",
  PrereqLtB = "Prereq LT-B",
  PrereqLtC = "Prereq LT-C",
  PrereqLtD = "Prereq LT-D",
  PrereqLtE = "Prereq LT-E",
}

export enum VIMElaPriorityStandards {
  Other = "Other",
  FLS = "FLS",
  UFLI = "UFLI",
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
  Six = "6",
}
