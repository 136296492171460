import {
  nonTableTitleTextStyles,
  tableTitleTextStyles,
} from "@utils/styleStrings";
import clsx from "clsx";
import { Icon, Tooltip } from "components/shared";
import { cellStyles } from "../../../../../../constants";
import { CONCERN_TYPE_W, DETAILS_MIN_W, STUDENT_W } from "../constants";

type Props = { readOnly: boolean };

export const ConcernSectionHeader = ({ readOnly }: Props) => (
  <div
    className={clsx(
      tableTitleTextStyles,
      "flex h-[42px] bg-slate-100/80 rounded-t-lg items-center"
    )}
  >
    <div className={cellStyles} style={{ width: `${CONCERN_TYPE_W}px` }}>
      Type
      {!readOnly && (
        <span className="ml-[2px] mb-[3px] text-gray-800 text-sm font-semibold">
          *
        </span>
      )}
    </div>

    <div className={cellStyles} style={{ width: `${STUDENT_W}px` }}>
      <Tooltip
        disabled={readOnly}
        className={clsx(
          "flex relative items-center gap-x-1",
          readOnly ? "cursor-default" : "cursor-pointer"
        )}
        content={
          <div className={nonTableTitleTextStyles}>
            {`Which student does this concern apply to? Select "General Class Concern" if it applies to the entire class. Required.`}
          </div>
        }
      >
        <span>Student</span>
        {!readOnly && (
          <Icon size={3} className="absolute -top-[3px] -right-[14px]" />
        )}
      </Tooltip>
    </div>

    <div
      className={clsx("flex-1", cellStyles)}
      style={{ minWidth: `${DETAILS_MIN_W}px` }}
    >
      Details
      {!readOnly && (
        <span className="ml-[2px] mb-[3px] text-gray-800 text-sm font-semibold">
          *
        </span>
      )}
    </div>
  </div>
);
