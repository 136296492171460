import { SessionConcern } from "../../../../../types";

export const STUDENT_W = 300;
export const DETAILS_MIN_W = 400;
export const CONCERN_TYPE_W = 220;

export const initConcern: SessionConcern = {
  concernNotes: "",
  concernType: null,
  studentId: "none",
};
