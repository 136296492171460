import {
  CohortAssignmentSubject,
  CohortAssignmentSubSubject,
} from "@generated/graphql";
import { getCohortSubjectText } from "@utils/labels";
import { Routes } from "@utils/routes";
import { formatArrayOfGrades } from "@utils/strings";
import classNames from "clsx";
import {
  CohortAssignmentSubjectBadge,
  IconText,
  Link,
} from "components/shared";

type Props = {
  grade?: string | null;
  cohortId?: string;
  className?: string;
  forHeader?: boolean;
  cohortName?: string;
  engagementId?: string;
  engagementName?: string;
  subject?: CohortAssignmentSubject;
  subSubject?: CohortAssignmentSubSubject;
};

export const CohortEventInfo = ({
  grade,
  subject,
  cohortId,
  className,
  subSubject,
  cohortName,
  engagementId,
  engagementName,
  forHeader = false,
}: Props) =>
  engagementId && cohortId && subject && subSubject ? (
    <div
      className={classNames(
        "flex text-lg gap-x-[15px] items-center",
        className
      )}
    >
      <IconText
        link
        forHeader={forHeader}
        icon="engagementFill"
        child={
          <Link
            customColor
            route={Routes.engagementsDashboard}
            routeProps={[`engagementId=${engagementId}`]}
          >
            {engagementName}
          </Link>
        }
      />
      <IconText
        link
        forHeader={forHeader}
        icon="cohortFill"
        child={
          <Link
            customColor
            route={Routes.engagementsDashboard}
            routeProps={[`engagementId=${engagementId}&cohortId=${cohortId}`]}
          >
            {cohortName}
          </Link>
        }
      />
      {grade && (
        <IconText
          forHeader={forHeader}
          icon="grade"
          child={formatArrayOfGrades(grade, true)}
        />
      )}

      {forHeader ? (
        <IconText
          forHeader={forHeader}
          icon="subject"
          child={getCohortSubjectText(subject, "long", subSubject)}
        />
      ) : (
        <CohortAssignmentSubjectBadge
          cohortSubject={subject}
          cohortSubSubject={subSubject}
        />
      )}
    </div>
  ) : null;
