import { VideoProvider } from "@generated/graphql";

const ROOM_TYPES = {
  Backdoor: "backdoor",
  Host: "host",
  Student: "student",
  All: "all",
} as const;

export type RoomType = (typeof ROOM_TYPES)[keyof typeof ROOM_TYPES];

// Define the supported RoomTypes for each VideoProvider.
const ZOOM_ROOM_TYPES = [ROOM_TYPES.Host, ROOM_TYPES.Student] as const;
export type ZoomRoomType = (typeof ZOOM_ROOM_TYPES)[number];

const GOOGLE_ROOM_TYPES = [
  ROOM_TYPES.All,
  ROOM_TYPES.Host,
  ROOM_TYPES.Student,
] as const;
export type GoogleRoomType = (typeof GOOGLE_ROOM_TYPES)[number];

const MICROSOFT_TEAMS_ROOM_TYPES = [
  ROOM_TYPES.Host,
  ROOM_TYPES.Student,
  ROOM_TYPES.All,
] as const;
export type MicrosoftTeamsRoomType =
  (typeof MICROSOFT_TEAMS_ROOM_TYPES)[number];

const MANUAL_ROOM_TYPES = [
  ROOM_TYPES.Host,
  ROOM_TYPES.Student,
  ROOM_TYPES.All,
] as const;
export type ManualRoomType = (typeof MANUAL_ROOM_TYPES)[number];

/**
 * Given a room type string and a video provider, return whether the room type
 * is valid for the video provider.
 *
 * Inspired in part by:
 * https://dev.to/hansott/how-to-check-if-string-is-member-of-union-type-1j4m
 *
 * @param value
 * @param engagementVideoProvider
 * @returns
 */
export function isValidRoomType(
  value: string,
  engagementVideoProvider: VideoProvider
): boolean {
  switch (engagementVideoProvider) {
    case VideoProvider.MicrosoftTeams:
      return MICROSOFT_TEAMS_ROOM_TYPES.includes(
        value as MicrosoftTeamsRoomType
      );
    case VideoProvider.Zoom:
      return ZOOM_ROOM_TYPES.includes(value as ZoomRoomType);
    case VideoProvider.GoogleMeets:
      return GOOGLE_ROOM_TYPES.includes(value as GoogleRoomType);
    case VideoProvider.Manual:
      return MANUAL_ROOM_TYPES.includes(value as ManualRoomType);
  }
}

/**
 * Gets the short URL for a student.
 *
 * @param origin
 * @param cohortPublicReferenceId
 * @param videoProvider
 * @returns
 */
export function getStudentShortMeetingLink(
  origin: string,
  cohortPublicReferenceId: string,
  videoProvider?: VideoProvider
) {
  switch (videoProvider) {
    case VideoProvider.MicrosoftTeams:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.All
      );
    case VideoProvider.Zoom:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.Student
      );
    case VideoProvider.GoogleMeets:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.All
      );
    case VideoProvider.Manual:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.All
      );
    default:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.Student
      );
  }
}

/**
 * Gets the short URL for a host.
 *
 * @param origin
 * @param cohortPublicReferenceId
 * @param videoProvider
 * @returns
 */
export function getHostShortMeetingLink(
  origin: string,
  cohortPublicReferenceId: string,
  videoProvider?: VideoProvider
) {
  switch (videoProvider) {
    case VideoProvider.MicrosoftTeams:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.All
      );
    case VideoProvider.Zoom:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.Host
      );
    case VideoProvider.GoogleMeets:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.All
      );
    case VideoProvider.Manual:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.All
      );
    default:
      return getShortMeetingLink(
        origin,
        cohortPublicReferenceId,
        ROOM_TYPES.Host
      );
  }
}

/**
 * Generates the short URL for a given room type.
 *
 * @param origin
 * @param cohortPublicReferenceId
 * @param roomType
 * @returns
 */
export function getShortMeetingLink(
  origin: string,
  cohortPublicReferenceId: string,
  roomType: RoomType
) {
  return `${origin.replace(
    /\/*$/,
    ""
  )}/meeting/${roomType}/${cohortPublicReferenceId}`;
}

export function getMeetingLinkForLoginCards(
  origin: string,
  organizationShortcut: string,
  externalStudentId: string
) {
  return `${origin.replace(
    /\/*$/,
    ""
  )}/${organizationShortcut}?externalStudentId=${externalStudentId}`;
}
