import { SessionConcernType } from "@generated/graphql";
import { getSessionConcernTypeText } from "@utils/labels";
import { SelectMenuOption } from "components/shared";
import {
  InstructionData,
  SessionConcernData,
  VIMElaPriorityStandards,
  VIMMathStrands,
  WIMMathLearningTargets,
} from "./types";

export const subSectionStyles =
  "border border-slate-400/60 rounded-lg p-4 bg-gray-50";

export const cellStyles = "flex items-center px-4 py-2";

export const initInstructionData: InstructionData = {
  strand: null,
  classCode: null,
  learningTarget: null,
  priorityStandard: null,
  instructionNotes: null,
};

export const initConcernData: SessionConcernData = {
  hasConcerns: null,
  concerns: [],
};

export const strandOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(VIMMathStrands).map(([strand, value]) => {
    return { id: strand, value };
  }),
];

export const learningTargetOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(WIMMathLearningTargets).map(([target, value]) => {
    return { id: target, value };
  }),
];

export const priorityStandardOptions: SelectMenuOption[] = [
  { id: "NONE", value: "--" },
  ...Object.entries(VIMElaPriorityStandards).map(([standard, value]) => {
    return { id: standard, value };
  }),
];

export const concernTypeOptions: SelectMenuOption<{
  concern: SessionConcernType | null;
}>[] = [
  { id: "NONE", value: "--", concern: null },
  ...Object.values(SessionConcernType).map((concern) => {
    const value = getSessionConcernTypeText(concern);
    return { id: value, value, concern };
  }),
];
