import { SessionReportModal_SessionReportModalDataFragment } from "@generated/graphql";
import clsx from "clsx";
import { Tooltip } from "components/shared";
import { getLogDateFormat } from "helpers/dateText";

type Props = {
  className?: string;
  showDates?: boolean;
  sessionReport: SessionReportModal_SessionReportModalDataFragment["sessionReport"];
};

export const UserLogDetails = ({
  sessionReport,
  className,
  showDates,
}: Props) => {
  const { submittedAt, updatedAt } = sessionReport || {};
  const updatedBy = sessionReport?.updatedBy?.fullName;
  const submittedBy = sessionReport?.submittedBy?.fullName;
  const updatedOn = getLogDateFormat(new Date(updatedAt));
  const submittedOn = getLogDateFormat(new Date(submittedAt));

  return (
    <div className={clsx("flex flex-col gap-y-1.5", className)}>
      {updatedBy && (
        <Tooltip
          disabled={showDates}
          className="cursor-pointer"
          tooltipProps={{ place: "left" }}
          content={`Updated On: ${updatedOn}`}
        >
          <div className="flex items-center gap-x-[6px] text-sm leading-none">
            <p className="font-bold text-slate-700">Updated By:</p>
            <p className="text-cyan-800 font-semibold">{updatedBy}</p>
            {showDates && (
              <p className="text-xs text-slate-500">({updatedOn})</p>
            )}
          </div>
        </Tooltip>
      )}
      {submittedBy && (
        <Tooltip
          disabled={showDates}
          className="cursor-pointer"
          tooltipProps={{ place: "left" }}
          content={`Submitted On: ${submittedOn}`}
        >
          <div className="flex items-center gap-x-[6px] text-sm leading-none">
            <p className="font-bold text-slate-700">Submitted By:</p>
            <p className="text-cyan-800 font-semibold">{submittedBy}</p>
            {showDates && (
              <p className="text-xs text-slate-500">({submittedOn})</p>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};
