import { Modal, Spinner } from "components/shared";
import { noop } from "lodash";
import { ReactNode, useState } from "react";
import { SessionReportModalBodyWrapper } from "./SessionReportModalBodyWrapper/SessionReportModalBodyWrapper";

type Props = {
  show: boolean;
  readOnly?: boolean;
  cohortSessionId: string;
  sessionReportId: string | null;
  onClose: () => void;
};

export const SessionReportModal = ({
  show,
  cohortSessionId,
  sessionReportId,
  readOnly = false,
  onClose,
}: Props) => {
  const [modalHeader, setModalHeader] = useState<ReactNode | null>(null);

  return (
    <Modal
      show={show}
      width="full"
      modalIsTopSticky
      dataTest="session-report-modal"
      onClose={readOnly ? onClose : noop}
      title={
        modalHeader === null ? (
          <div className="flex justify-center items-center w-full h-[300px]">
            <Spinner color="text-blue-600" size={10} />
          </div>
        ) : (
          modalHeader
        )
      }
    >
      <SessionReportModalBodyWrapper
        readOnly={readOnly}
        cohortSessionId={cohortSessionId}
        sessionReportId={sessionReportId}
        onClose={onClose}
        setModalHeader={setModalHeader}
      />
    </Modal>
  );
};
