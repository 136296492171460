import { StudentPerformance } from "../../../../../types";

export const NAME_W = 210;
export const ATTENDANCE_W = 160;
export const PERFORMANCE_MIN_W = 650;
export const SCALE_W = 280;
export const SCALE_READONLY_W = 210;
export const NOTES_MIN_W = 210;

export const initStudentPerformance: StudentPerformance = {
  mastery: null,
  engagement: null,
  performanceNotes: null,
};

export const cellStyles = "flex items-center px-4 py-2";
