import { SelectMenuOption } from "components/shared";
import { StudentEvaluationRow } from "components/shared/AttendanceGrades/SessionStudentEvaluationTable";

export const getStudentConcernOptions = (
  students: StudentEvaluationRow[]
): SelectMenuOption[] => [
  {
    id: "--",
    value: "--",
  },
  {
    id: "GENERAL",
    value: "General Class Concern",
  },
  ...students.map(({ fullName, studentId }) => ({
    id: studentId,
    value: fullName,
  })),
];
